import {
  Badge,
  Box,
  Button,
  CloseIcon,
  Divider,
  Heading,
  HStack,
  IconButton,
  Modal,
  ScrollView,
  Spacer,
  Stack,
  Text,
  VStack,
} from 'native-base';
import { useRef } from 'react';

import { Pricing } from './Pricing';
import { getAccordionInfo } from './getAccordionInfo';

import { RecipeType } from '@/api/types/accountServices';
import {
  Accordion,
  Image,
  ResponsiveCarousel,
  ReviewCarousel,
  AccordionItem,
  PDPModalProps,
} from '@/components/Elements';
import { ASSETS_URL } from '@/constants';
import { useBodyScrollLock } from '@/hooks';
import { capitalize } from '@/utils';

// We should consider using backend DB for these images...
function getPDPHeroImages(recipeType: RecipeType, id: string) {
  return Array(5)
    .fill('')
    .map((_, i) => `${ASSETS_URL}/pictos/PDP/${capitalize(recipeType)}/${id}/${i}.png`);
}

export const ShopPDPModal = ({
  recipe,
  prices = [],
  discountedPrices = [],
  pricingUnits = [],
  isTrial = false,
  updateState,
  buttonText,
  showCTA = false, // PDP modal CTAs are being hidden for now -- PRODUCT-1768
  ...props
}: PDPModalProps) => {
  const { id, name, type, info_pills, description, reviews, product_contents } = recipe;
  const accordionInfo = getAccordionInfo(recipe);

  const initialRef = useRef(null);
  const reviewsWithRecipe = reviews
    .map((review) => ({
      ...review,
      recipe: { id, name, type },
    }))
    .sort((review1, review2) => review1.seq_no - review2.seq_no);

  const lockRef = useBodyScrollLock({ enabled: props.isOpen });

  const heroImages = getPDPHeroImages(type, id);

  return (
    <Modal size="full" initialFocusRef={initialRef} useRNModal focusable {...props}>
      <Modal.Content
        bg="white"
        my={{ base: '5vh', lg: 4 }}
        minHeight={{ base: '85vh', lg: '95vh' }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        w={{ base: '95vw', lg: '772px' }}
        borderWidth={{ base: '2px', lg: '3px' }}
      >
        <ScrollView ref={lockRef} style={{ width: '100%' }}>
          <IconButton
            position="sticky"
            top={4}
            right={8}
            zIndex={1000}
            alignSelf="flex-end"
            p="0px"
            onPress={() => props.onClose?.()}
            icon={
              <CloseIcon
                alt="Close product detail page"
                color="black"
                size={{ base: '11px', md: '14px' }}
              />
            }
          />
          <VStack
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            w={{ base: '95vw', lg: '772px' }}
            p={{ base: 4, lg: 6 }}
            h="100%"
          >
            <ResponsiveCarousel
              variant="gallery"
              data={heroImages}
              itemWidth={{ base: 288, md: 484 }}
              width={{ base: 500, md: 772 }}
              height={{ base: 264, md: 460 }}
              disableLoop
              renderItem={({ item }) => {
                return (
                  <VStack
                    width="100%"
                    height="100%"
                    overflow="hidden"
                    justifyContent="center"
                    alignItems="center"
                    pr={{ base: '6px', md: '12px' }}
                    pl={{ base: '6px', md: '12px' }}
                  >
                    <Image
                      source={{ uri: item }}
                      alt={name + ' detail image'}
                      width="100%"
                      height="100%"
                      resizeMode="cover"
                    />
                  </VStack>
                );
              }}
            />
            <Heading size={{ base: 'bodyLg', lg: 'titleSm' }} fontWeight="bold">
              {name}
            </Heading>
            <Spacer height={{ base: 1, lg: 2 }} width={{ base: 1, lg: 2 }} />
            <Stack space={1} alignItems="center">
              {prices?.map((price, i) => (
                <Pricing
                  key={`${price}${i}`}
                  itemType={type}
                  pricing={Number(price)}
                  pricingUnit={pricingUnits[i] || 'meal'}
                  isTrial={isTrial && i === 0}
                  discountedPrice={
                    i < discountedPrices.length && discountedPrices[i] !== null
                      ? Number(discountedPrices[i])
                      : null
                  }
                />
              )) || null}
            </Stack>
            <Spacer height={{ base: 1, lg: 2 }} width={{ base: 1, lg: 2 }} />
            {product_contents?.[0]?.text ? (
              <Stack>
                <Text fontWeight="medium" fontSize="textSmToMd" fontFamily="secondary">
                  {product_contents[0].text}
                </Text>
              </Stack>
            ) : null}
            <Spacer height={{ base: 4, lg: 6 }} width={{ base: 4, lg: 6 }} />
            <Box display="flex" flexDir="row" justifyContent="center" alignItems="center">
              {info_pills.length > 0 &&
                info_pills.map((obj, i, arr) => {
                  return (
                    <Box
                      key={obj.slug + i.toString() + 'container'}
                      display="flex"
                      flexDir="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Badge
                        _text={{
                          fontSize: { base: '12px', lg: '14px' },
                          lineHeight: { base: '16px', lg: '18px' },
                        }}
                        key={obj.slug + i.toString()}
                        variant="smallInfoPill"
                      >
                        {obj.text}
                      </Badge>
                      {i < arr.length - 1 ? <Spacer size={{ base: 1, lg: 2 }} /> : null}
                    </Box>
                  );
                })}
            </Box>
            <Spacer height={{ base: 4, lg: 6 }} width={{ base: 4, lg: 6 }} />
            <Box w={{ lg: '400px' }} px="0px">
              <Divider bg="gallery" />
              <Spacer size={{ base: 4, lg: 6 }} />
              {/* *************** OVERVIEW ********************* */}
              <Box>
                <Text size="bodySmToMd" fontWeight="medium" fontFamily="secondary">
                  {description}
                </Text>
              </Box>
              <Spacer size={{ base: 4, lg: 6 }} />
              <Divider bg="gallery" />
              {/* *************** ACCORDION ********************* */}
              <Accordion
                items={[
                  {
                    heading: 'Product Details',
                    body: (
                      <Stack>
                        {accordionInfo.map((item, index) => (
                          <Stack>
                            <AccordionItem
                              key={index}
                              item={item}
                              getOpenItem={() => index}
                              updateOpenItem={() => index}
                              hideArrow
                              isChild
                              isLastItem={index === accordionInfo.length - 1}
                            />
                          </Stack>
                        ))}
                      </Stack>
                    ),
                    index: 1,
                  },
                ]}
              />
              <Divider bg="gallery" />
            </Box>
            {/* *************** REVIEWS ********************* */}
            {reviews && reviews.length > 0 && (
              <VStack justifyContent="center" alignItems="center" my={{ base: 4, lg: 6 }} space={5}>
                <Heading size="bodyMlToTitleMd" textAlign="center" fontWeight="bold">
                  Reviews
                </Heading>
                <ReviewCarousel data={reviewsWithRecipe} width={{ base: 311, md: 400 }} />
              </VStack>
            )}
          </VStack>
        </ScrollView>
        {showCTA ? (
          <HStack
            justifyContent="center"
            alignItems="center"
            alignSelf="center"
            w="100%"
            position="sticky"
            pt={6}
            pb={{ base: 4, lg: 6 }}
            bottom={0}
            h="fit-content"
            bg="white"
            style={{
              shadowColor: 'rgba(0,0,0,0.15)',
              shadowOffset: { width: -4, height: -4 },
              shadowRadius: 4,
            }}
          >
            <Button
              w={{ base: '311px', lg: '290px' }}
              borderWidth={{ base: '2px', lg: '3px' }}
              onPress={() => {
                updateState?.();
                props.onClose?.();
              }}
              variant="primary"
            >
              {buttonText}
            </Button>
          </HStack>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};
