import { Item, Price, ProductType } from './mealPlanServices';
import { SurveyResponse } from './surveyResponseServices';

export interface Account {
  auth_token?: string;
  id: string;
  status: string;
  first_charge_processed_at: string;
  has_valid_payment_method: boolean;
  reason_payment_method_not_valid: string;
  addresses: Address[];
  pets: Pet[];
  petplans: PetPlan[];
  orders: {
    upcoming: Order[];
    processing: Order[];
    past: Order[];
  };
  check_in_sent: boolean;
  contact_preference_delivery: ContactPreference;
  contact_preference_order: ContactPreference;
  contact_preference_marketing: ContactPreference;
  email: string;
  first_name: string;
  is_active: boolean;
  is_cx_representative: boolean;
  last_name: string;
  phone_number?: string | null;
  puppy_check_in_eligible: boolean;
  puppy_check_in_sent: boolean;
  stripe_customer_id: string;
  survey_responses: SurveyResponse[];
  is_migrated: boolean;
  viewed_portal_tutorial: boolean;
}

export interface PetPlanRecipeData {
  id: string;
  seq_no: number;
  name: string;
  description: string;
  type: RecipeType;
}

export interface PetPlanProduct {
  id: string;
  product_id: string;
  name: string;
  code: string;
  quantity: number;
  frequency: number;
  type: 'SUBSCRIPTION' | 'ADDON';
  stripe_subscription_id: string | null;
  recipes: PetPlanRecipeData[];
  price_per_week: string;
  product_type: ProductType;
  status: PetPlanProductStatus;
  pet_plan: string;
  created: string;
  unit_price: string;
}

export enum PetPlanStatus {
  ACTIVE = 'ACTIVE',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  INITIALIZING = 'INITIALIZING',
}

export enum PetPlanProductStatus {
  ACTIVE = 'ACTIVE',
  SCHEDULED = 'SCHEDULED',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  PAUSED = 'PAUSED',
  NOT_CHARGED = 'NOT_CHARGED',
}

export interface PetPlan {
  id: string;
  created: string;
  last_updated: string;
  status_date: string;
  start_date: string;
  recipes: string[];
  recipe_data: PetPlanRecipeData[];
  plan_size: number;
  calories_per_day: number;
  is_topper: boolean;
  frequency: number;
  base_price: number;
  discount_amt: number;
  status: PetPlanStatus;
  user_last_change: string | null;
  account: string;
  discounts: Discount[];
  products: PetPlanProduct[];
  pet: string;
}

export interface Address {
  id: string;
  account: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  latitude: string;
  longitude: string;
  is_primary: boolean;
  type: string;
  shipping_name: string;
}

export enum ChargeType {
  // TODO: missing types (also missing in BE)
  TRIAL = 'TRIAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
}

export enum ChargeStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  MAX_RETRY_REACHED = 'MAX_RETRY_REACHED',
  SKIPPED = 'SKIPPED',
  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  ERROR = 'ERROR',
}

export interface ChargePayload {
  id: string;
  amount: number;
  status: string;
  charges: {
    data: [
      {
        id: string;
        billing_details: {
          name: string | null;
          email: string | null;
          phone: string | null;
          address: {
            city: string | null;
            line1: string | null;
            line2: string | null;
            state: string | null;
            country: string | null;
            postal_code: string | null;
          };
        };
        payment_method_details: {
          card: {
            brand: string;
            last4: string;
            [key: string]: any;
          };
          type: string;
        };
        [key: string]: any;
      }
    ];
    [key: string]: any;
  };
  created: number;
  [key: string]: any;
}
export interface Charge {
  charge_type: ChargeType;
  created: string;
  error: string;
  error_type: string;
  id: string;
  last_charge_attempt_date: string;
  last_updated: string;
  number_times_tried: number;
  order: string;
  payload: ChargePayload;
  payment: string;
  processed_at: string;
  retry_date: string;
  scheduled_at: string;
  status: ChargeStatus;
  status_date: string;
  stripe_charge_id: string;
  subtotal_price: string;
  tax_lines: string;
  total_discounts: string;
  total_price: string;
  total_refunds: string;
  total_tax: string;
  transaction_id: string;
  card_number?: string;
  card_type?: string;
}

export enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum OrderStatus {
  PREVIEW = 'PREVIEW',
  PROCESSING = 'PROCESSING',
  CHARGING = 'CHARGING',
  MAX_RETRY_REACHED = 'MAX_RETRY_REACHED',
  READY_FOR_FULFILLMENT = 'READY_FOR_FULFILLMENT',
  FULFILLING = 'FULFILLING',
  PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
  FULFILLED = 'FULFILLED',
  COMPLETE = 'COMPLETE',
  HOLD = 'HOLD',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
}

export enum FulfillmentStatus {
  SCHEDULED = 'SCHEDULED',
  FULFILLMENTS_CREATED = 'FULFILLMENTS_CREATED',
  PREPARING = 'PREPARING',
  ORDERDESK_SENT = 'ORDERDESK_SENT',
  CREATING_AFTERSHIP_SHIPMENTS = 'CREATING_AFTERSHIP_SHIPMENTS',
  PARTIALLY_SHIPPED = 'PARTIALLY_SHIPPED',
  SHIPPED = 'SHIPPED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  DELIVERY_FAILED = 'DELIVERY_FAILED',
}

export interface Order {
  applied_tax: string;
  charge: Charge;
  id: string;
  counter: string;
  counter_int: number;
  products: OrderProduct[];
  created: string;
  recharge_order_id: string;
  shopify_order_id: string;
  shopify_user_id: string;
  last_updated: string;
  charged: string;
  scheduled: string;
  shipped: string;
  delivered: string;
  subtotal_price: string;
  total_discounts: string;
  total_price: string;
  total_refunds: string;
  total_tax: string;
  status: OrderStatus;
  order_type: OrderType;
  transaction_id: string;
  stripe_charge_id: string;
  stripe_payment_method_id: string;
  payment_type: PaymentType;
  account: string;
  billing_address: Address;
  shipping_address: Address;
  discounts: OrderDiscount[];
  historical_coupon_code: string | null;
  fulfillments: Fulfillment[];
}

export interface Fulfillment {
  address1: string;
  address2: string | null;
  box_number: number;
  city: string;
  company: string | null;
  country: string;
  created: string;
  first_name: string;
  id: string;
  last_name: string;
  last_updated: string;
  order: string;
  orderdesk_id: string;
  pet: string;
  shipments: Shipment[];
  shopify_order_id: null;
  state: string;
  status: FulfillmentStatus;
  zip: string;
}

type AftershipObject = {
  id: string;
  last_updated_at: string;
  tag: string;
};
export interface Shipment {
  aftership_exception_message: string | null;
  aftership_object: AftershipObject;
  aftership_tracking_id: string | null;
  carrier_code: CarrierCode | string | null;
  cost_of_shipping: string;
  created: string;
  delivered: string | null;
  exception: string | null;
  fulfillment: string;
  id: string;
  in_transit: string | null;
  last_updated: string;
  orderdesk_id: string | null;
  out_for_delivery: string | null;
  returned: string | null;
  shipped: string | null;
  shipping_class: string | null;
  tracking_number: string | null;
  voided: string | null;
}

export enum CarrierCode {
  FEDEX = 'fedex',
  UPS = 'ups',
}

export enum DiscountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface OrderDiscount {
  id: string;
  discount: Discount;
  created: string;
  last_updated: string;
  status_date: string;
  status: DiscountStatus;
  amount: string;
  order: string;
}

export enum OrderType {
  TRIAL = 'TRIAL',
  SUBSCRIPTION = 'SUBSCRIPTION',
  ONETIME = 'ONETIME',
}

export enum DiscountType {
  PERCENTAGE_DISCOUNT = 'Percentage Discount',
  AMOUNT_DISCOUNT = 'Amount Discount',
}

export enum DiscountLimitType {
  UNLIMITED = 'Unlimited',
  X_CUSTOMERS = 'X customers',
  X_ORDERS_PER_CUSTOMER = 'X orders per customer',
  X_ORDERS_FOR_JUST_ONE_CUSTOMER = 'X orders per customer for just one customer',
  X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT = 'X orders per customer for subscription trial split',
}

export interface Discount {
  id: string;
  created: string;
  last_updated: string;
  code: string;
  name: string;
  description: string | null;
  discount_type: DiscountType;
  value: string;
  trial_value: string | null;
  available_start_date: string | null;
  available_end_date: string | null;
  admin_only: boolean;
  limit_type: DiscountLimitType | null;
  limit_value: number | null;
  is_active: boolean;
}

export enum OrderProductType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  ADDON = 'ADDON',
  ONETIME = 'ONETIME',
}

export interface OrderProductPetPlan {
  id: string;
  pet_id: string;
  pet_name: string;
  plan_size_code: string;
  recipes: string[];
  start_date: string;
  status: PetPlanStatus;
}

export interface OrderProduct {
  id: string;
  code: string;
  name: string;
  /**
   * Note: pet_plan may not exist on older migrated orders.
   */
  pet_plan?: OrderProductPetPlan;
  product: string;
  product_type: ProductType;
  quantity: number;
  recipes: RecipePeek[];
  type: OrderProductType;
  unit_price: string;
  price: Price;
  image_uri: string | null;
  recurring?: boolean;
}

export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  DISCONTINUED = 'DISCONTINUED',
}

export interface Product {
  description: string;
  stripe_product_id: string | null;
  calories_per_pack: string | null;
  lbs_per_pack: string | null;
  status: ProductStatus;
  code: string;
  name: string;
  frequency: number;
  total_packs: number;
  type: ProductType;
  items: Item[];
  recipes: Recipe[];
}

export interface MerchandiseProduct {
  id: string;
  code: Product['code'];
  name: string;
  description: string;
  type: ProductType;
  price: string;
  discounted_price: string | null;
}

export enum RecipeType {
  UNKIBBLE = 'UNKIBBLE',
  FRESH = 'FRESH',
  SNACK = 'SNACK',
  SUPPLEMENT = 'SUPPLEMENT',
  SCOOP = 'SCOOP',
  MATERIAL = 'MATERIAL',
  MERCH = 'MERCH',
}

export interface Recipe {
  id: string;
  seq_no: number;
  status?: ProductStatus;
  name: string;
  description: string;
  type: RecipeType;
  ingredients: string[];
  inactive_ingredients: string[];
  vitamins: string[];
  analysis: string[];
  aafco_statement: string;
  recipe_info: RecipeDataPoint[];
  info_pills: RecipeDataPoint[];
  mini_pictos: RecipeDataPoint[];
  product_contents: RecipeDataPoint[];
  cautions: RecipeDataPoint[];
  for_use_in_dogs_only: RecipeDataPoint[];
  directions_for_use: RecipeDataPoint[];
  warnings: RecipeDataPoint[];
  reviews: Review[];
}

export interface RecipeFull extends Recipe {
  price: string;
  default_product: DefaultProduct;
  discounted_price: string;
}

export interface DefaultProduct {
  code: string;
  id?: string;
  name: string;
  price: Price;
}

export interface RecipePeek {
  id: string;
  seq_no: number;
  name: string;
  description: string;
  aafco_statement: string;
  type: RecipeType;
}

export interface RecipeDataPoint {
  slug: string;
  text: string;
  seq_no: number;
}

export interface Review {
  reviewer_name: string;
  rating: number;
  review: string;
  seq_no: number;
}

export enum ContactPreference {
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
  PHONE = 'PHONE',
  ANY = 'ANY',
  NONE = 'NONE',
}

export enum PetStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DUPLICATE = 'DUPLICATE',
  PAUSED = 'PAUSED',
  CANCELLED = 'CANCELLED',
  DELETED = 'DELETED',
  DECEASED = 'DECEASED',
  DECEASED_TO_NOT_LIST = 'DECEASED_TO_NOT_LIST',
}

export const INACTIVE_STATUSES = [
  PetStatus.DECEASED,
  PetStatus.DECEASED_TO_NOT_LIST,
  PetStatus.CANCELLED,
  PetStatus.PAUSED,
];

export enum PetGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export interface Pet {
  id: string;
  created: string;
  last_updated: string;
  status: PetStatus;
  name: string;
  account: string;
  breeds: string[];
  breed_details: BreedDetail[];
  gender: PetGender;
  birth_day?: number;
  birth_month: number | null;
  birth_year: number;
  properties: PetProperties;
  pet_plan: PetPlan;
}

export interface PetProperties {
  id: string;
  created: string;
  last_updated: string;
  pet: string;
  recommended_calories_day: null;
  weight: number;
  neutered: boolean;
  body_type: string;
  lifestyle: string;
  health_conditions: string[];
  audit_trail_status: string;
}

export interface BreedDetail {
  name: string;
  slug: string;
  description: string | null;
}

export enum DiscountExclusiveType {
  WINBACK_10_PERCENT = 'WINBACK_10_PERCENT',
  WINBACK_30_PERCENT = 'WINBACK_30_PERCENT',
}
